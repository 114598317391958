.blog-post-container .blog-post {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.blog-post-container .blog-post ul li,
.blog-post-container .blog-post ol li {
  margin-bottom: 1rem;
}

.blog-post-container .img-fluid img {
  width: 100%;
  height: auto;
}

.blog-post-container .img-center {
  width: 140%;
  margin-left: -20%;
  height: auto;
}

.blog-post-container .img-center img {
  width: 100%;
  height: auto;
}

@media (max-width: theme("screens.lg")) {
  .blog-post-container .img-center {
    max-width: 100%;
    margin-left: 0;
  }
}
