.rs-content {
  @apply whitespace-pre-line;
}

.rs-content .content-url {
  @apply inline-block align-bottom max-w-[50%] truncate;
}

.rs-content .content-url .fragment,
.rs-content .content-url .scheme {
  @apply hidden;
}
