@import "@angular/cdk/text-field-prebuilt.css";

/*
This style is require for ion-nput to focus properly.
@see https://github.com/ionic-team/ionic-framework/blob/7315e0157b917d2e3586c64f8082026827812943/core/src/components/input/input.scss#L114
*/
.cloned-input {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
