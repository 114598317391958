[truncate] + a.truncate-toggle {
  display: none;
}
.truncate-collapse + a.truncate-toggle {
  font-weight: 500;
  display: block;
  position: relative;
  z-index: 10;
  margin-top: 1rem;
}
.truncate-collapse.truncating {
  overflow: hidden;
  position: relative;
}
.truncate-collapse.truncating:after {
  display: block;
  content: "";
  height: 50%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    white 65%,
    white 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 );
}
.truncate-collapse.truncating + a.truncate-toggle {
  margin-top: -1rem;
}
