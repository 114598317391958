@font-face {
  font-family: "Avenir Next Demi";
  src: url("/assets/fonts/AvenirNext-DemiBold.woff2") format("woff2"),
    url("/assets/fonts/AvenirNext-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/AvenirNext-Medium.woff2") format("woff2"),
    url("/assets/fonts/AvenirNext-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/AvenirNext-Regular.woff2") format("woff2"),
    url("/assets/fonts/AvenirNext-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/AvenirNext-Bold.woff2") format("woff2"),
    url("/assets/fonts/AvenirNext-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
