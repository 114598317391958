html {
  height: 100%;
}

body {
  min-height: 100%;
}

body,
html {
  font-family: "Avenir Next", sans-serif;
  letter-spacing: 0.0025rem;
  position: relative;
  padding: 0;
}

body app-root,
html app-root {
  display: block;
  height: 100%;
}

h1 {
  @apply text-3xl lg:text-4xl font-medium;
}

h2 {
  @apply text-2xl lg:text-3xl font-medium;
}

h3 {
  @apply text-xl lg:text-2xl font-medium;
}

h4 {
  @apply text-lg lg:text-xl font-medium;
}

h5 {
  @apply text-base lg:text-lg font-medium;
}

h6 {
  @apply text-sm lg:text-base font-medium;
}

a {
  @apply text-blue hover:text-blue-700 no-underline;
}

a.disabled {
  @apply text-neutral-600;
}

.page-container {
  min-height: 33vh;
}
